
import { ElConfigProvider } from 'element-plus';
import { defineComponent } from "vue";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default defineComponent({
  name: "App",
  components: {
    ElConfigProvider
  },
  data() {
    return {
      locale: zhCn
    }
  },
  methods: {},
  provide() {
    return {
      
    }
  }
});
